<script setup>
    const props = defineProps({
        initialRemainingSeconds: {
            type: Number,
            required: true,
        },
    });

    import {add, isBefore} from 'date-fns';
    import {goToSSO} from '@jetCommon/helpers/navigation.js';
    import {intervalToTimeString} from '@jetCommon/helpers/date.js';

    import JetIcon from '@jetDS/components/JetIcon.vue';

    const {$api} = inject('jet');

    const impersonationSessionEnd = add(new Date(), {seconds: props.initialRemainingSeconds});

    const remainingTimeText = ref('');

    function logout() {
        $api.auth
            .logout()
            .then(() => {
                goToSSO();
            })
            .catch($api.end);
    }

    function updateRemainingTimeText() {
        const now = new Date();
        if (isBefore(now, impersonationSessionEnd)) {
            remainingTimeText.value = intervalToTimeString({
                start: now,
                end: impersonationSessionEnd,
            });
        } else {
            remainingTimeText.value = '00:00';
            logout();
        }
    }

    setInterval(updateRemainingTimeText, 1000);
</script>

<template>
    <div>
        <div class="impersonation-border" />
        <div class="impersonation-info medium base">
            <JetIcon name="user-magnifying-glass" icon-style="regular" />
            Tempo rimanente {{ remainingTimeText }}
            <span class="impersonation-logout semibold base">
                <a href="#" @click.prevent="logout">Termina ora</a>
            </span>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .impersonation-border {
        border: 4px solid var(--el-color-danger);
        inset: 0;
        pointer-events: none;
        position: absolute;
        z-index: 3000;
    }

    .impersonation-info {
        align-items: center;
        background-color: var(--el-color-danger);
        color: white;
        display: flex;
        gap: 16px;
        inset: auto 0 0 auto;
        padding: 8px 16px;
        pointer-events: none;
        position: absolute;
        width: 356px;
        z-index: 3001;
        left: 0;
        bottom: 0;
    }

    .impersonation-logout {
        margin-left: auto;
        pointer-events: auto;

        a {
            color: white;
        }
    }
</style>
