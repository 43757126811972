import {ApiBase} from '@jetCommon/api/base.js';

export default class CompanyApiCommon extends ApiBase {
    static resourceName = 'companies';

    uploadAvatar(companyId, data) {
        return this.apiPostForm(`${companyId}/avatar/`, data);
    }

    removeAvatar(companyId) {
        return this.apiDelete(`${companyId}/avatar/`);
    }

    retrieveCompanySettings(companyId, expandSalaryPaymentSettings) {
        return this.apiGet(`${companyId}/company_settings/`, {
            params: {
                ...(expandSalaryPaymentSettings && {expand_salary_payment_settings: true}),
            },
        });
    }

    retrieveCompanyAddOns(companyId) {
        return this.apiGet(`${companyId}/company_add_ons/`);
    }

    updateCompanySettings(companyId, data) {
        return this.patch(`${companyId}/company_settings`, data);
    }

    getCUStatsForYear(companyId, year) {
        return this.apiGet(`${companyId}/get_cu_stats_for_year/${year}/`);
    }

    getShiftTemplates(companyId, params) {
        return this.apiGet(`${companyId}/shift_templates`, {params});
    }
    getIntercomConfiguration(companyId) {
        return this.apiGet(`${companyId}/intercom_configuration/`);
    }

    getCalendar(companyId, config = {}) {
        return this.apiGet(`${companyId}/absence_calendar/`, config);
    }

    getCalendarSetup(companyId, config = {}) {
        return this.apiGet(`${companyId}/absence_calendar/setup/`, config);
    }

    getCalendarEmployeeChoices(companyId, config = {}) {
        return this.apiGet(`${companyId}/absence_calendar/employee_choices/`, config);
    }

    getOnboardingSummary(companyId) {
        return this.apiGet(`${companyId}/onboarding_summary/`);
    }

    getComplianceSummary(companyId) {
        return this.apiGet(`${companyId}/compliance_summary/`);
    }

    getCompanyAbsenceCalendarSettings(companyId) {
        return this.apiGet(`${companyId}/company_absence_calendar_settings/`);
    }
}
